import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import "../styles/Terms.scss";
import Seo from "../components/common/SEO";

const content = [
  {
    id: 1,
    metaTitle: "Disclaimer - Invovcom",
    metaDescription:
      "Invocom automated customer support will not be liable to bear loss or damage of any nature because the services on the website are free of charge.",
  },
];

const disclaimer = () => {
  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="disclaimer/"
          />
        </div>
      ))}
      <Container>
        <Box className="pageTerms-wrapper">
          <Box className="sub-content-disclaimer">
            <Typography variant="h1">Disclaimer</Typography>
            <Typography variantMapping="p">
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties, and conditions relating to our
              website and the use of this website. Nothing in this disclaimer
              will:
              <br />
              <br />
              <Box className="listBox">
                <ul>
                  <li>
                    limit or exclude our or your liability for death or personal
                    injury;
                  </li>
                  <li>
                    limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation;
                  </li>
                  <li>
                    limit any of our or your liabilities in any way that is not
                    permitted under applicable law; or
                  </li>
                  <li>
                    exclude any of our or your liabilities that may not be
                    excluded under applicable law.
                  </li>
                </ul>
              </Box>
              <br />
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph; and (b) govern all liabilities arising under the
              disclaimer, including liabilities arising in contract, in tort,
              and for breach of statutory duty.
              <br />
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default disclaimer;
